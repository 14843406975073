import { useState, useEffect } from 'react'
import { CourseAnnouncementsDataClass } from '../../classes/CourseDataClasses/CourseAnnouncementsDataClass'
import { fetcher } from '../../classes/Fetcher'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { Announcement, Course } from '../../types/courseTypes'
import { useCommunityCourses } from '../community/useCommunityCourses'
import { useCurrentUser } from '../self/useSelfInfo'
import { useUserCourses } from './useUserCourses'

export const useAllCourseAnnouncements = (communityID?: string, cachable: boolean = true) => {
  const [courseAnnouncements, setCourseAnnouncements] = useState(null as null | Announcement[])
  const courses = useCommunityCourses(communityID)
  useEffect(() => {
    if (!courses) return
    const courseAnnouncementsMap = new Map<string, Set<Announcement>>()
    let cancels = [] as (() => void)[]
    const updateAnnouncements = () => {
      // merge all course announcements into one array
      const announcements = [] as Announcement[]
      courses.forEach(course => {
        announcements.push(...(courseAnnouncementsMap.get(course.id) ?? []))
      })
      announcements.sort((a, b) => b.created - a.created)
      const oldThreshold = Date.now() - 1000 * 60 * 60 * 24 * 14
      const notOld = announcements.findIndex(a => a.created >= oldThreshold)

      setCourseAnnouncements(announcements.slice(0, Math.min(~notOld ? notOld: 20, 20)))
      // console.log('announcements', announcements.slice(0, Math.min(~notOld ? notOld: 20, 20)), notOld,20)
    }

    courses.forEach(course => {
      const courseAnnouncements = CourseAnnouncementsDataClass.getInstance(course.id).announcements
      courseAnnouncementsMap.set(course.id, new Set(courseAnnouncements))
      const setAnnouncements = (event: any) => {
        courseAnnouncementsMap.set(course.id, new Set(event.announcements))
        updateAnnouncements()
      }
      CourseAnnouncementsDataClass.getInstance(course.id).on('announcementsUpdate', setAnnouncements)
      cancels.push(() => {
        CourseAnnouncementsDataClass.getInstance(course.id).off('announcementsUpdate', setAnnouncements)
      })
    })
    updateAnnouncements()
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [courses, cachable])
  return courseAnnouncements
}
