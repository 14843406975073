import { useEffect, useState } from 'react'
import { ScheduleClass } from '../../../../../utils/classes/ScheduleClass'
import { usePrimaryCommunity } from '../../../../../utils/hooks/community/usePrimaryCommunity'
import { useMasterSchedule } from '../../../../../utils/hooks/schedule/useMasterSchedule'
import { useUserSchedule } from '../../../../../utils/hooks/schedule/useUserSchedulePrefs'
import { ScheduleClassEvent } from '../../../../../utils/types/scheduleTypes'
import { ClassBlock } from './ClassBlockTypes/ClassBlock'

export const DaySchedule = () => {
  const community = usePrimaryCommunity()
  const masterSchedule = useMasterSchedule(community?.community)
  const schedulePrefs = useUserSchedule(community?.community)
  const [scheduleDay, setScheduleDay] = useState(null as ScheduleClassEvent[] | null)
  useEffect(() => {
    const update = () => {
      ScheduleClass.getInstance()
        .parseScheduleDay(masterSchedule ?? undefined)
        .then(day => {
          setScheduleDay(day ?? null)
        })
    }
    update()
    const interval = setInterval(() => {
      update()
    }, 1000)
    return () => clearInterval(interval)
  }, [masterSchedule, schedulePrefs])
  return (
    <div className={`relative w-full h-full`}>
      <div
        className={`flex flex-col gap-4 h-full absolute top-0 left-0 overflow-auto pb-6 w-full scrollbar-none text-gray-900/80`}
      >
        {scheduleDay?.map((classBlock, index) => (
          <ClassBlock key={index} classBlock={classBlock} />
        ))}
      </div>
    </div>
  )
}
