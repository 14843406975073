import { useEffect, useState } from 'react'
import { usePrimaryCommunity } from '../../../utils/hooks/community/usePrimaryCommunity'
import { useCurrentUser } from '../../../utils/hooks/self/useSelfInfo'

export const DashboardBackground = () => {
  const user = useCurrentUser(true)
  const [hue, setHue] = useState(0)
  const [bg, setBg] = useState('')
  useEffect(()=>{
    if (user) {
      setBg(`/defaults/background/${user?.theme === 1 ? `dark` : `light`}.png`)
    }
  },[user])
  const community = usePrimaryCommunity()
  // alert(defaultBG)
  return (
    <div
      className={`w-full h-full bg-cover bg-center absolute top-0 left-0`}
      style={{
        backgroundImage: `url(${bg})`,
        filter: `hue-rotate(${hue}deg)`,
      }}
    />
  )
}
