import { useState, useEffect } from 'react'
import { CourseMaterialsDataClass } from '../../classes/CourseDataClasses/CourseAssignmentDataClass'
import { fetcher } from '../../classes/Fetcher'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { Announcement, Course, UpcomingCourseEvent } from '../../types/courseTypes'
import { useCommunityCourses } from '../community/useCommunityCourses'
import { useCurrentUser } from '../self/useSelfInfo'
import { useUserCourses } from './useUserCourses'

export const useAllCourseUpcoming = (communityID?: string, cachable: boolean = true) => {
  const [courseUpcoming, setCourseUpcoming] = useState(null as null | UpcomingCourseEvent[])
  const courses = useCommunityCourses(communityID)
  useEffect(() => {
    if (!courses) return
    const courseUpcomingMap = new Map<string, Set<UpcomingCourseEvent>>()
    let cancels = [] as (() => void)[]
    const updateUpcoming = () => {
      // merge all course upcomings into one array
      const upcomings = [] as UpcomingCourseEvent[]
      courses.forEach(course => {
        upcomings.push(...(courseUpcomingMap.get(course.id) ?? []))
      })
      upcomings.sort((a, b) => b.start - a.start)
      const oldThreshold = Date.now() - 1000 * 60 * 60 * 24 * 14
      const notOld = upcomings.findIndex(a => a.start >= oldThreshold)
      setCourseUpcoming(upcomings.slice(0, Math.min(~notOld && notOld ? notOld : 20, 20)))
    }

    courses.forEach(course => {
      const courseUpcoming = Array.from(
        CourseMaterialsDataClass.getInstance(course.id).upcomingEvents?.values() || []
      ).map(e => ({ ...e, course: course })) as UpcomingCourseEvent[]
      courseUpcomingMap.set(course.id, new Set(courseUpcoming))
      const setUpcomings = (event: any) => {
        const courseUpcoming = Array.from(
          CourseMaterialsDataClass.getInstance(course.id).upcomingEvents?.values() || []
        ).map(e => ({ ...e, course: course })) as UpcomingCourseEvent[]
        courseUpcomingMap.set(course.id, new Set(courseUpcoming))
        updateUpcoming()
      }
      CourseMaterialsDataClass.getInstance(course.id).on('upcomingEventsUpdate', setUpcomings)
      cancels.push(() => {
        CourseMaterialsDataClass.getInstance(course.id).off('upcomingEventsUpdate', setUpcomings)
      })
      CourseMaterialsDataClass.getInstance(course.id).getUpcomingEvents()
    })
    updateUpcoming()
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [courses, cachable])
  return courseUpcoming
}
