import { BiBed } from 'react-icons/bi'
import { meshifyColor } from '../../../../../../utils/helperFunctions.ts/meshGradients'
import { usePrimaryCommunity } from '../../../../../../utils/hooks/community/usePrimaryCommunity'
import { useMasterSchedule } from '../../../../../../utils/hooks/schedule/useMasterSchedule'
import { useUserSchedule } from '../../../../../../utils/hooks/schedule/useUserSchedulePrefs'
import { PrepClassScheduleType, ScheduleClassEvent } from '../../../../../../utils/types/scheduleTypes'
import { GenericBlockTemplate } from './GenericBlockTemplate'

export const PrepClassBlock = (props: { classBlock: PrepClassScheduleType; sb: ScheduleClassEvent }) => {
  const { classBlock, sb } = props
  const community = usePrimaryCommunity()
  const schedule = useMasterSchedule(community?.community)
  const userOptions = useUserSchedule(community?.community)
  if (userOptions?.options?.showPreps)
    return (
      <GenericBlockTemplate
        name={'Prep Period'}
        caption={schedule?.periods[classBlock.periodID]}
        image={
          <div className={`w-full h-full brightness-90 flex flex-row items-center justify-center relative`}>
            <div
              className={`absolute top-0 left-0 w-full h-full blur-lg`}
              style={{
                ...meshifyColor(community?.communityObject?.colors.primary!),
              }}
            />
            <BiBed className={`w-10 h-10 grayscale-[0.5]`} />
          </div>
        }
        start={sb.from}
        end={sb.to}
      />
    )
  return null
}
