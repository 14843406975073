import { visit } from 'unist-util-visit'
import emojis from '../../../public/emoji/emojis.json'

interface Options {
  className: string
}

export const customEmojiRegex = new RegExp(`(${(emojis.map((v: string) => { return `:${v}:` })).join('|')})`, 'g')

export const rehypeCustomEmoji = (options: Options) => {
  

  return (tree: any) => {
    visit(tree, 'text', node => {
      let matches = node.value.matchAll(customEmojiRegex)
      if ([...matches].length === 0) {
        return
      }

      matches = [...node.value.matchAll(customEmojiRegex)]

      node.children = []

      for (const match of matches) {
        if (node.value.indexOf(match[0]) !== 0) {
          node.children.push({
            type: 'text',
            value: node.value.slice(0, node.value.indexOf(match[0])),
          })
          node.children.push({
            tagName: 'img',
            type: 'element',
            properties: {
              alt: node.value.slice(node.value.indexOf(match[0]), node.value.indexOf(match[0]) + match[0].length),
              className: [options.className],
              decoding: 'async',
              draggable: 'false',
              src: `/emoji/svg/${node.value.slice(
                node.value.indexOf(match[0]) + 1,
                node.value.indexOf(match[0]) + match[0].length - 1
              )}.svg`,
            },
            children: [],
          })
          node.value = node.value.slice(node.value.indexOf(match[0]) + match[0].length, node.value.length)
        } else {
          node.children.push({
            tagName: 'img',
            type: 'element',
            properties: {
              alt: node.value.slice(0, match[0].length),
              className: [options.className],
              decoding: 'async',
              draggable: 'false',
              src: `/emoji/svg/${node.value.slice(1, match[0].length - 1)}.svg`,
            },
            children: [],
          })
          node.value = node.value.slice(match[0].length, node.value.length)
        }
      }
      if (node.value !== '') {
        node.children.push({
          type: 'text',
          value: node.value,
        })
      }
      node.value = undefined

      node.tagName = 'span'
      node.type = 'element'
    })
  }
}
