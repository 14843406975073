import { PencilAltIcon, SpeakerphoneIcon } from '@heroicons/react/outline'
import { usePrimaryCommunity } from '../../../../utils/hooks/community/usePrimaryCommunity'
import { useAllCourseAnnouncements } from '../../../../utils/hooks/course/useAllCourseAnnouncements'
import { getCurrentBreakpoint } from '../../../../utils/tailwindHelpers/getBreakpoint'
import { DashboardAnnouncementViewer } from './Announcement'
const ToggleButton = (props: { icon: JSX.Element; onClick: () => void; className?: string; active: boolean }) => {
  const { icon: Icon, onClick, className, active } = props
  const colors = usePrimaryCommunity()?.communityObject?.colors
  return (
    <div
      className={`w-10 h-10 relative hover:bg-opacity-50 transition-all rounded-full cursor-pointer group  p-4 dark:text-gray-300`}
      onClick={onClick}
    >
      <div
        className={`z-20 absolute top-0 left-0 p-2 w-full h-full hover:text-${colors?.primary} ${
          active && `!text-${colors?.primary} saturate-[.75]`
        } hover:text-opacity-90 hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-md rounded-xl transition-all duration-300`}
      >
        {Icon}
      </div>
    </div>
  )
}
export const DashboardAnnouncements = (props: { open: number; setOpen: (open: number) => void }) => {
  const { open, setOpen } = props
  const community = usePrimaryCommunity()
  const announcements = useAllCourseAnnouncements(community?.community)
  return (
    <div className={`flex flex-col gap-8 pt-16 w-full h-full p-4 2xl:hidden md:flex`}>
      <div className={`flex flex-row justify-between pr-2 items-center z-20`}>
        <h1 className={`text-2xl font-bold text-gray-100`}>Latest Announcements</h1>
      </div>
      <div className={`relative flex-grow`}>
        <div className={`absolute top-0 left-0 w-full h-full flex flex-col gap-4 overflow-auto`}>
          {announcements?.map(announcement => (
            <DashboardAnnouncementViewer announcement={announcement} />
          ))}
        </div>
      </div>
    </div>
  )
}
