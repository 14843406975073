import { NextPage } from 'next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import MobileDashboardBottomBar from '../../components/Course/CourseDashboard/MobileDashboardBottomBar'
import { DashboardAnnouncements } from '../../components/Dashboard/DashboardComponents/Announcements/Announcements'
import { DashboardBackground } from '../../components/Dashboard/DashboardComponents/Background'
import { DashboardUpcoming } from '../../components/Dashboard/DashboardComponents/EventsInfo/Events'
import { MixedDashboardAndUpcoming } from '../../components/Dashboard/DashboardComponents/MixxedAnnouncementsAndUpcoming'
import { CurrentClassSchedule } from '../../components/Dashboard/DashboardComponents/Schedule/CurrentClass'
import { DaySchedule } from '../../components/Dashboard/DashboardComponents/Schedule/DaySchedule/DaySchedule'
import { Schedule } from '../../components/Dashboard/DashboardComponents/Schedule/Schedule'
import { useCurrentUser } from '../../utils/hooks/self/useSelfInfo'
const Description =
  'Disadus is a tool that helps students increase their productivity and form school communities with their peers to achieve academic success!'
const MainApp: NextPage = () => {
  const user = useCurrentUser()
  const router = useRouter()
  const [openPage, setOpenPage] = useState(0)
  useEffect(() => {
    if (user) {
      console.log(user)
    }
    if (user === null) {
      router.push('/login')
    }
    console.log('user', user)
  }, [user])
  return (
    <div className={`w-full h-full flex flex-col`}>
      <div className={`flex-grow relative`}>
        <DashboardBackground />
        <div
          className={`grid inf:grid-cols-12 3xl:grid-cols-9 2xl:grid-cols-6 xl:grid-cols-2 xl:gap-4 md:grid-cols-1 w-full h-full z-10 absolute gap-12`}
        >
          <div className={`col-span-3 xl:col-span-1 ${openPage !== 0 && `md:hidden`}`}>
            <Schedule />
          </div>
          <div className={`col-span-3 xl:col-span-1 ${openPage !== 1 && `md:hidden`}`}>
            <DashboardAnnouncements open={openPage} setOpen={setOpenPage} />
            <MixedDashboardAndUpcoming open={openPage} setOpen={setOpenPage} />
          </div>
          <div className={`col-span-3 2xl:hidden md:block ${openPage !== 2 && `md:hidden`}`}>
            <DashboardUpcoming open={openPage} setOpen={setOpenPage} />
          </div>
        </div>
      </div>
      <MobileDashboardBottomBar open={openPage} setOpen={setOpenPage} />
    </div>
  )
}
export default MainApp
