import { SpeakerphoneIcon, PencilAltIcon } from '@heroicons/react/outline'
import announcements from '../../../../pages/app/courses/[course]/announcements'
import { usePrimaryCommunity } from '../../../../utils/hooks/community/usePrimaryCommunity'
import { useAllCourseUpcoming } from '../../../../utils/hooks/course/useAllCourseUpcoming'
import { DashboardUpcomingViewer } from './UpcomingEvent/Event'

export const DashboardUpcoming = (props: { open: number; setOpen: (open: number) => void }) => {
  const { open, setOpen } = props
  const community = usePrimaryCommunity()
  const upcoming = useAllCourseUpcoming(community?.community)
  return (
    <div className={`flex flex-col gap-8 pt-16 w-full h-full p-4`}>
      <div className={`flex flex-row justify-between pr-2 items-center z-20`}>
        <h1 className={`text-2xl font-bold  text-gray-100`}>Upcoming</h1>
      </div>
      <div className={`relative flex-grow`}>
        <div className={`absolute top-0 left-0 w-full h-full flex flex-col gap-4 overflow-auto`}>
          {upcoming?.map(event => (
            <DashboardUpcomingViewer event={event} />
          ))}
        </div>
      </div>
    </div>
  )
}
