import { SearchIcon, DotsHorizontalIcon, CalendarIcon, SpeakerphoneIcon, SparklesIcon, PencilAltIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { QuickSearchClass } from '../../../utils/classes/QuickSearchClass'
import { SidebarClass } from '../../../utils/classes/SidebarClass'
import { meshifyColor } from '../../../utils/helperFunctions.ts/meshGradients'
import { usePrimaryCommunity } from '../../../utils/hooks/community/usePrimaryCommunity'
import { useCurrentUser } from '../../../utils/hooks/self/useSelfInfo'
import { ModuleItem } from '../Modules/ModuleItem'
const MobileBottomButton = (props: { icon: JSX.Element; onClick: () => void; className?: string; active: boolean }) => {
  const { icon: Icon, onClick, className, active } = props
  const router = useRouter()
  const colors = usePrimaryCommunity()?.communityObject?.colors
  return (
    <div
      className={`w-10 h-10 relative hover:bg-opacity-50 transition-all rounded-full cursor-pointer group  p-4 dark:text-gray-300`}
      onClick={onClick}
    >
      <div
        className={`z-20 absolute top-0 left-0 p-2 w-full h-full hover:text-${colors?.primary} ${
          active && `!text-${colors?.primary} saturate-[.75]`
        } hover:text-opacity-90 hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-md rounded-xl transition-all duration-300`}
      >
        {Icon}
      </div>
      <div
        className={`lg:hidden absolute top-1/2 right-0 w-max pointer-events-none translate-x-[calc(100%+2rem)] -translate-y-1/2 bg-gray-900 text-gray-50 p-2 rounded-lg scale-0 group-hover:scale-100 transition-transform origin-left`}
      >
        More Options
      </div>
    </div>
  )
}
export const MobileDashboardBottomBar = (props: { open: number; setOpen: (open: number) => void }) => {
  const { open, setOpen } = props
  const colors = usePrimaryCommunity()?.communityObject?.colors
  const router = useRouter()
  const user = useCurrentUser()
  if (!user) return null
  return (
    <div
      className={`w-full h-min hidden md:flex flex-shrink-0 dark:bg-gray-750/80 bg-gray-150 transition-all flex-row gap-2 p-2 shadow-lg z-30  text-gray-600 dark:text-gray-50 relative drop-shadow-lg items-center`}
    >
      {/* <CommunityItem open={sidebarOpen} /> */}
      {/* <OpenSidebar open={sidebarOpen} /> */}
      {/* <QuickSearchItem open={sidebarOpen} /> */}
      <div className={`flex flex-grow items-center justify-center gap-6 xs:gap-2`}>
        <MobileBottomButton icon={<CalendarIcon />} onClick={() => setOpen(0)} active={open === 0} />
        <MobileBottomButton icon={<SpeakerphoneIcon />} onClick={() => setOpen(1)} active={open === 1} />

        <div
          className={`absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-${colors?.primary} w-16 h-16 cursor-pointer hover:shadow-lg drop-shadow-md transition-all group`}
          onClick={() => {
            QuickSearchClass.getInstance().setState(true)
          }}
        >
          <div className={`w-full h-full relative p-4 text-gray-50/90 group-hover:text-white drop-shadow-md`}>
            <div
              className={`w-full h-full absolute top-0 left-0 opacity-0 group-hover:opacity-30 transition-all rounded-full`}
              style={{ ...meshifyColor(colors?.primary!) }}
            ></div>
            <SearchIcon className={` w-full h-full group-hover:text-shadow z-10`} />
          </div>
        </div>
        <div className={` rounded-full w-16 h-12 opacity-0 pointer-events-none`}></div>
        <MobileBottomButton icon={<PencilAltIcon />} onClick={() => setOpen(2)} active={open === 2} />
        {/* <MobileBottomButton icon={<SparklesIcon />} onClick={() => setOpen(3)} active={open === 3} /> */}
        {/* {modules?.slice(2, 3).map((module, i) => (
          <ModuleItem module={module} open={false} index={i} key={`i${i}module${module.id}`} />
        ))} */}
        <div
          className={`w-10 h-10 relative hover:bg-opacity-50 transition-all rounded-full cursor-pointer group  p-4 dark:text-gray-300`}
          onClick={() => {
            SidebarClass.getInstance().updateState(true)
          }}
        >
          <div
            className={`z-20 absolute top-0 left-0 p-2 w-full h-full hover:text-${colors?.primary} hover:text-opacity-90 hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-md rounded-xl transition-all duration-300`}
          >
            <DotsHorizontalIcon />
          </div>
          <div
            className={`lg:hidden absolute top-1/2 right-0 w-max pointer-events-none translate-x-[calc(100%+2rem)] -translate-y-1/2 bg-gray-900 text-gray-50 p-2 rounded-lg scale-0 group-hover:scale-100 transition-transform origin-left`}
          >
            More Options
          </div>
        </div>
      </div>
      {/* <UserItem open={sidebarOpen} /> */}
    </div>
  )
}
export default MobileDashboardBottomBar
