import EventEmitter from 'events'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { CourseAnnouncementDataEvent } from '../../types/courseDataTypes'
import { Announcement } from '../../types/courseTypes'
import { fetcher } from '../Fetcher'
import { SocketManager } from '../SocketManager'

export class CourseAnnouncementsDataClass extends EventEmitter {
  constructor(courseID: string) {
    super()
    this.courseID = courseID
    this.init()
  }
  static _instanceMap = new Map<string, CourseAnnouncementsDataClass>()
  static getInstance(courseID: string) {
    if (!CourseAnnouncementsDataClass._instanceMap.has(courseID)) {
      CourseAnnouncementsDataClass._instanceMap.set(courseID, new CourseAnnouncementsDataClass(courseID))
    }
    return CourseAnnouncementsDataClass._instanceMap.get(courseID)!
  }
  courseID: string
  announcements: Announcement[] = []
  async init() {
    this.loadCachedAnnouncements()
    this.getAnnouncements()
    SocketManager.getInstance().on(`announcementUpdate.${this.courseID}`, this.onAnnouncementUpdate.bind(this))
  }
  async onAnnouncementUpdate(announcement: Announcement) {
    this.announcements.push(announcement)
    this.emit('announcementsUpdate', {
      announcements: this.announcements,
      cached: false,
    } as CourseAnnouncementDataEvent)
    await (await getPersistentStore).setItem(`course.${this.courseID}.announcements`, this.announcements)
  }
  async getAnnouncements() {
    const response = await fetcher(`${API_DOMAIN}/courses/${this.courseID}/announcements`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CANVAS_API_TOKEN}`,
      },
    })
    const data = await response.json()
    this.announcements = data.announcements
    this.emit('announcementsUpdate', {
      announcements: this.announcements,
      cached: false,
    } as CourseAnnouncementDataEvent)
    await (await getPersistentStore).setItem(`course.${this.courseID}.announcements`, this.announcements)
  }

  async loadCachedAnnouncements() {
    const announcements = await (await getPersistentStore).getItem(`course.${this.courseID}.announcements`)
    if (announcements) {
      this.announcements = announcements as Announcement[]
      this.emit('announcementsUpdate', {
        announcements: this.announcements,
        cached: true,
      } as CourseAnnouncementDataEvent)
    }
  }
}
