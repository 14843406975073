import { PencilAltIcon, SpeakerphoneIcon } from '@heroicons/react/outline'
import { usePrimaryCommunity } from '../../../utils/hooks/community/usePrimaryCommunity'
import { useAllCourseAnnouncements } from '../../../utils/hooks/course/useAllCourseAnnouncements'
import { useAllCourseUpcoming } from '../../../utils/hooks/course/useAllCourseUpcoming'
import { DashboardAnnouncementViewer } from './Announcements/Announcement'
import { DashboardUpcomingViewer } from './EventsInfo/UpcomingEvent/Event'
const ToggleButton = (props: { icon: JSX.Element; onClick: () => void; className?: string; active: boolean }) => {
  const { icon: Icon, onClick, className, active } = props
  const colors = usePrimaryCommunity()?.communityObject?.colors
  return (
    <div
      className={`w-10 h-10 relative hover:bg-opacity-50 transition-all rounded-full cursor-pointer group  p-4 dark:text-gray-300`}
      onClick={onClick}
    >
      <div
        className={`z-20 absolute top-0 left-0 p-2 w-full h-full hover:text-${colors?.primary} ${
          active && `!text-${colors?.primary} saturate-[.75]`
        } hover:text-opacity-90 hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-md rounded-xl transition-all duration-300`}
      >
        {Icon}
      </div>
    </div>
  )
}
export const MixedDashboardAndUpcoming = (props: { open: number; setOpen: (open: number) => void }) => {
  const { open, setOpen } = props
  const community = usePrimaryCommunity()
  const announcements = useAllCourseAnnouncements(community?.community)
  const upcoming = useAllCourseUpcoming(community?.community)
  return (
    <div className={`flex-col gap-8 pt-16 w-full h-full p-4 2xl:flex hidden`}>
      <div className={`flex flex-row justify-between pr-2 items-center z-20`}>
        <h1 className={`text-2xl font-bold text-gray-100 ${open !== 2 ? `2xl:block md:hidden` : `2xl:hidden inf:block`}`}>
          Latest Announcements
        </h1>
        <h1 className={`text-2xl font-bold text-gray-100 ${open === 2 ? `2xl:block inf:hidden  md:hidden` : `hidden`}`}>
          Assignments Info
        </h1>
        <div
          className={`p-2 dark:bg-gray-850/70 bg-gray-150/70 backdrop-blur-lg xl:flex-row gap-2 rounded-xl 2xl:flex md:hidden hidden`}
        >
          <ToggleButton icon={<SpeakerphoneIcon />} onClick={() => setOpen(1)} active={open !== 2} />
          <ToggleButton icon={<PencilAltIcon />} onClick={() => setOpen(2)} active={open === 2} />
        </div>
      </div>
      <div className={`relative flex-grow`}>
        <div className={`absolute top-0 left-0 w-full h-full flex flex-col gap-4 overflow-auto`}>
          {open !== 2 &&
            announcements?.map(announcement => <DashboardAnnouncementViewer announcement={announcement} />)}
          {open === 2 && upcoming?.map(event => <DashboardUpcomingViewer event={event} />)}
        </div>
      </div>
    </div>
  )
}
