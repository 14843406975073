import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useState } from 'react'
import RemoveMarkdown from 'remove-markdown'
import { usePrimaryCommunity } from '../../../../../utils/hooks/community/usePrimaryCommunity'
import { Assignment, UpcomingCourseEvent } from '../../../../../utils/types/courseTypes'
import { AnnouncementModal } from '../../Announcements/AnnouncementModal'

export const DashboardUpcomingViewer = (props: { event: UpcomingCourseEvent }) => {
  const { event } = props
  const community = usePrimaryCommunity()
  const [openPopup, setOpenPopup] = useState(false)
  const router = useRouter()
  const parsed = RemoveMarkdown(event.description ?? '', {
    stripListLeaders: true,
    gfm: false,
  })
  return (
    <>
      <div
        className={`flex flex-col gap-2 p-4 dark:bg-gray-800/80 backdrop-blur-md bg-gray-100/70 shadow-sm rounded-xl w-full transition-all ease-out duration-150 cursor-pointer flex-shrink-0 group`}
        onClick={e => {
          // setShowmore(!showmore)
          if (event.type === 'assignment') {
            router.push(`/app/courses/${event.course?.id}/materials/${event.assignmentID}`)
            return;
          }
          setOpenPopup(true)
          e.stopPropagation()
        }}
      >
        <div className={`flex flex-row gap-4 justify-between`}>
          <div className={`flex flex-row gap-2 items-center`}>
            <img className={`w-8 h-8 rounded-lg object-cover`} src={event.course?.icon} />
            <span>
              <span className={`text-sm dark:text-gray-500`}>{event.course?.name}</span>
            </span>
          </div>
          <div>
            <span className={`text-xs dark:text-gray-500`}>{dayjs(event.start).fromNow()}</span>
          </div>
        </div>
        <div className={`flex flex-col gap-2 flex-grow`}>
          {event.title && <span className={`font-bold leading-6 text-gray-800 dark:text-gray-150`}>{event.title}</span>}
          {!!parsed.length && (
            <div className={`relative h-18 2xl:h-[4.5rem] w-full line-clamp-3 leading-6`}>{parsed}</div>
          )}
          <div className={`flex flex-row justify-between items-center`}>
            <span
              className={`text-gray-400 group-hover:text-${community?.communityObject?.colors.primary} cursor-pointer transition-all duration-150 `}
            >
              Preview
            </span>
            {/* <span className={`text-xs dark:text-gray-500 text-gray-700/40`}>
              {dayjs(event.).format('MMMM DD YYYY')} at {dayjs(announcement.created).format('hh:mm A')}
            </span> */}
          </div>
        </div>
      </div>
      {/* <AnnouncementModal announcement={announcement} open={openPopup} onClose={() => setOpenPopup(false)} /> */}
    </>
  )
}
