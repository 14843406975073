import { ClockIcon, PencilIcon } from '@heroicons/react/outline'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(utc)
dayjs.extend(relativeTime)
import { Announcement } from '../../../../utils/types/courseTypes'
import { Modal } from '../../../Hiddens/Modal'
import MarkdownRenderer from '../../../Utils/MarkdownRenderer'

export const AnnouncementModal = (props: { announcement: Announcement; open: boolean; onClose: () => void }) => {
  const { announcement, open, onClose } = props
  return (
    <Modal visible={open} onClose={onClose} hideBG className={`origin-top !duration-200`}>
      <div className={`relative max-w-full w-screen h-screen`} onClick={onClose}>
        <div className={`absolute top-0 left-0 w-full h-full overflow-auto py-12 flex justify-center items-start`}>
          <div
            className={`max-w-prose w-[90%] dark:bg-gray-800 bg-gray-100 col-span-10 p-12 2xl:p-8 xl:gap-6 md:gap-4 rounded-xl shadow-lg flex flex-col gap-8 h-fit`}
            onClick={(e) => { e.stopPropagation() }}
          >
            {announcement.title && (
              <h1 className={`dark:text-gray-50 font-montserrat text-4xl font-bold`}>{announcement?.title}</h1>
            )}
            {announcement.user && (
              <div className={`flex flex-row gap-4 items-center p-2 dark:bg-gray-850 w-fit rounded-xl shadow-lg`}>
                <img
                  className={`w-8 h-8 rounded-full object-cover`}
                  src={announcement.user.picture}
                  alt={announcement.user.name}
                />
                <span className={`font-wsans text-lg`}>{announcement.user.name}</span>
              </div>
            )}
            <div className={`flex flex-col gap-4`}>
              <span className={`dark:text-gray-500 flex flex-row items-center`}>
                <ClockIcon className={`w-5 h-5 mr-2`} />
                {announcement.created ? (
                  <>
                    Created {dayjs(announcement.created).format(`MMMM DD YYYY`)}{' '}
                    {dayjs(announcement.created).format(`hh:mm:ssA`)}
                  </>
                ) : (
                  `Unknown Creation Data`
                )}
              </span>
              {announcement.updated && announcement.updated !== announcement.created && (
                <span className={`dark:text-gray-500 flex flex-row items-center`}>
                  <PencilIcon className={`w-5 h-5 mr-2`} />
                  <>
                    Updated {dayjs(announcement.updated).format(`MMMM DD YYYY`)}{' '}
                    {dayjs(announcement.updated).format(`hh:mm:ssA`)}
                  </>
                </span>
              )}
            </div>
            {!!announcement?.body && <MarkdownRenderer>{announcement?.body || ''}</MarkdownRenderer>}
            {/* 
        {material.type === 'file' && (
          <div className={`flex-grow relative`}>
            <div className={`w-full h-full absolute inset-0 flex flex-col items-center justify-center`}>
              {attachments?.map(attachment => (
                <MaterialFileViewer key={attachment.id} attachment={attachment} />
              ))}
            </div>
          </div>
        )} */}
          </div>
        </div>
      </div>
    </Modal>
  )
}
