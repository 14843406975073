import { usePrimaryCommunity } from '../../../../../../utils/hooks/community/usePrimaryCommunity'
import { useCourse } from '../../../../../../utils/hooks/course/useCourse'
import { useMasterSchedule } from '../../../../../../utils/hooks/schedule/useMasterSchedule'
import { ClassScheduleType, ScheduleClassEvent } from '../../../../../../utils/types/scheduleTypes'
import { GenericBlockTemplate } from './GenericBlockTemplate'

export const ClassClassBlock = (props: { classBlock: ClassScheduleType; sb: ScheduleClassEvent }) => {
  const { classBlock, sb } = props
  const community = usePrimaryCommunity()
  const schedule = useMasterSchedule(community?.community)
  const course = useCourse(classBlock.class)
  if (!course) {
    return null
  }
  return (
    <GenericBlockTemplate
      name={course.name}
      caption={schedule?.periods[classBlock.periodID]}
      image={course.icon}
      start={sb.from}
      end={sb.to}
    />
  )
}
